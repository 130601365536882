import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery, useQueryClient } from 'react-query'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { TEXT_STYLES } from 'TEXT_STYLES'

import { AlertsDropdownLoadingState } from 'src/js/components/navbar/AlertsDropdownLoadingState/AlertsDropdownLoadingState'
import { AlertsDropdownDiff } from 'src/js/components/navbar/AlertsDropdownDiff/AlertsDropdownDiff'
import { AlertsDropdownEmptyState } from 'src/js/components/navbar/AlertsDropdownEmptyState/AlertsDropdownEmptyState'

import { alertDiffType } from 'src/js/types/alerts'

import { ALERTS_NOTIFICATION_VIEWED } from 'constants/activation_points/alerts'
import { NAVBAR_ALERT_DIFFS_LIMIT } from 'constants/navbar/alerts'

import { setLastViewedAlertId, goToAlertedType } from 'actions/alerts'
import { trackPendoEvent } from 'services/pendo'
import { Button, ButtonType, ClickableIcon, Popover } from '@junglescout/edna'
import { slideInDown, slideOutUp } from 'src/js/components/common/animations'
import { sendCtaClickEvent, sendNavClickEvent } from 'helpers/segment'
import { TOP_NAV } from 'constants/activation_points/navbar'

const AlertWrapper = styled.div`
  position: relative;
  display: flex;
`
const AlertDot = styled.span`
  position: absolute;
  top: 8px;
  right: 6px;
  width: 11px;
  height: 11px;
  background-color: ${props => props.theme.colors.red500};
  border: solid 2px ${props => props.theme.colors.white};
  border-radius: 50%;
`

const HeaderButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
`

const AlertsDropdownWrapper = styled.div`
  cursor: default;
  max-height: 630px;
  overflow: auto;
  border-radius: 2px;
`
const Header = styled.div`
  display: flex;
  height: 52px;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  background-color: ${props => props.theme.colors.white};
  border-bottom: 1px solid ${props => props.theme.colors.grey100};
`
const Title = styled.div`
  ${TEXT_STYLES.H2Black}
`

const MostRecentText = styled.div`
  ${TEXT_STYLES.H5Black}
  color: ${props => props.theme.colors.grey600};
  text-transform: uppercase;
`

const MostRecentWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  background-color: ${props => props.theme.colors.grey025};
  border-bottom: 1px solid ${props => props.theme.colors.grey100};
  padding: 6px 16px;
`

const StyledPopover = styled(Popover)`
  max-height: 630px;
  &[data-state='open'] {
    animation: ${slideInDown} 200ms ease-in-out;
  }
  &[data-state='closed'] {
    animation: ${slideOutUp} 200ms ease-in-out;
  }
`

const displayContent = showAlertDot => {
  return (
    <AlertWrapper>
      <ClickableIcon
        name="BELL_FILLED"
        height="24px"
        width="24px"
        color="grey400"
        padding="6px"
        ariaLabel="Alerts Icon"
      />
      {showAlertDot && <AlertDot data-testid="alert-dot" />}
    </AlertWrapper>
  )
}

const DropdownContent = ({ alertDiffs, isLoading, closeDropdown }) => {
  const { t } = useTranslation(['generic', 'alerts'])

  const history = useHistory()
  const dispatch = useDispatch()

  const isEmpty = alertDiffs.length === 0

  const renderBody = () => {
    if (!isEmpty) {
      return (
        <>
          {alertDiffs.map(diff => (
            <AlertsDropdownDiff
              key={diff.id}
              diff={diff}
              handleClick={alert => {
                dispatch(goToAlertedType(alert))
                closeDropdown()
                sendCtaClickEvent({
                  location: 'Alerts Dropdown',
                  text: 'Alerts Row Clicked'
                })
              }}
            />
          ))}
        </>
      )
    }

    if (isLoading) {
      return <AlertsDropdownLoadingState />
    }

    return <AlertsDropdownEmptyState />
  }

  return (
    <AlertsDropdownWrapper>
      <Header>
        <Title>{t('generic:Alerts', 'Alerts')}</Title>
        <HeaderButtonWrapper>
          <Button
            btnType={ButtonType.TERTIARY}
            iconName="BELL"
            mini
            onClick={() => {
              sendCtaClickEvent({
                location: 'Alerts Dropdown',
                text: 'View All'
              })
              history.push({
                pathname: `/alerts`
              })
              closeDropdown()
            }}>
            {t('alerts:AlertsDropdown.viewAllAlerts', 'View All')}
          </Button>
          <Button
            btnType={ButtonType.TERTIARY}
            iconName="SETTINGS"
            mini
            onClick={() => {
              sendCtaClickEvent({
                location: 'Alerts Dropdown',
                text: 'Settings'
              })
              history.push({
                pathname: `/account/alert-settings`,
                state: { from: '/alerts' }
              })
              closeDropdown()
            }}>
            {t('alerts:AlertsDropdown.alertSettings', 'Settings')}
          </Button>
        </HeaderButtonWrapper>
      </Header>
      <MostRecentWrapper>
        <MostRecentText>
          {t('alerts:AlertsDropdown.mostRecent', 'Most Recent')}
        </MostRecentText>
      </MostRecentWrapper>
      {renderBody()}
    </AlertsDropdownWrapper>
  )
}

DropdownContent.defaultProps = {
  alertDiffs: []
}

DropdownContent.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  alertDiffs: PropTypes.arrayOf(alertDiffType),
  closeDropdown: PropTypes.func.isRequired
}

export const AlertsDropdown = () => {
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)

  const { data: alertDiffs, isLoading: alertDiffsIsLoading } = useQuery(
    ['api/alert_diffs', { data: { limit: NAVBAR_ALERT_DIFFS_LIMIT } }],
    {
      enabled: isOpen,
      onSuccess: data => {
        const latestAlertId = data?.length ? data[0].alert.id : null
        dispatch(setLastViewedAlertId(latestAlertId))
      }
    }
  )

  const queryClient = useQueryClient()

  const { data: lastUnreadAlertId, isLoading } = useQuery('api/alerts/unread', {
    refetchInterval: 10 * 60 * 1000, // refetch every 10 minutes
    onSuccess: result => {
      if (result) {
        queryClient.invalidateQueries('api/alert_diffs')
      }
    }
  })

  const lastViewedAlertId = useSelector(state => state.alerts.lastViewedAlertId)

  useEffect(() => {
    dispatch(setLastViewedAlertId())
  }, [dispatch])

  const closeDropdown = useCallback(() => {
    setIsOpen(false)
  }, [])

  const hasUnreadAlerts =
    !isLoading &&
    lastUnreadAlertId !== null &&
    lastUnreadAlertId !== lastViewedAlertId

  return (
    <StyledPopover
      align="end"
      width="380px"
      padding="0px"
      sideOffset={6}
      alignOffset={-4}
      open={isOpen}
      content={
        <DropdownContent
          alertDiffs={alertDiffs}
          isLoading={alertDiffsIsLoading}
          closeDropdown={closeDropdown}
        />
      }
      onOpenChange={showing => {
        setIsOpen(showing)
        if (showing) {
          sendNavClickEvent({
            location: TOP_NAV,
            navItem: 'Alerts Dropdown'
          })
          trackPendoEvent(ALERTS_NOTIFICATION_VIEWED)
        }
      }}>
      {displayContent(hasUnreadAlerts)}
    </StyledPopover>
  )
}
