import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Button, ButtonType, Text, ClickableIcon } from '@junglescout/edna'

import { COMPETITIVE_INTELLIGENCE_URL } from 'constants/routes'
import { Features } from 'constants/feature_names'
import { SET_GLOBAL_DATA } from 'constants/action_types'
import { CI_INCENTIVE_UPGRADE_LINK } from 'constants/competitive_intelligence/common'

import { isUpgradeIncentiveEligible } from 'helpers/competitive_intelligence'
import { getLocalData, setLocalData } from 'helpers/storage'
import { sendCtaClickEvent } from 'helpers/segment'

import { useFeatureFlag } from 'hooks/feature_flag'

import { NavbarWrapper } from 'ui_elements/NavbarWrapper'

const StyledNavbarWrapper = styled(NavbarWrapper)`
  border-bottom: 2px solid #3f0291;
`
const BannerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: linear-gradient(90deg, #5e1bb9 0%, #7c16ae 100%);
  padding: 0 20px 0 30px;
`
const LeftSection = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`
const RightSection = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`
const DealTag = styled.div`
  border-radius: 8px;
  background-color: ${props => props.theme.colors.white};
  padding: 6px 12px;
  width: fit-content;
`
const LearnMoreButton = styled(Button)`
  color: ${props => props.theme.colors.white};
  :hover {
    background: unset;
  }
`

const CI_UPGRADE_BANNER_LOCAL_STORAGE_KEY = 'CI-Upgrade-Banner-Dismissed'

export const CIUpgradeBanner = ({ isMobile }) => {
  const { t } = useTranslation(['competitive_intelligence', 'generic'])

  const history = useHistory()
  const dispatch = useDispatch()

  const [isBannerDismissed, setIsBannerDismissedState] = useState(
    getLocalData(CI_UPGRADE_BANNER_LOCAL_STORAGE_KEY)
  )

  const {
    id,
    billing_frequency,
    status,
    membership_created_at,
    group
  } = useSelector(state => state.globalData.membershipInfo)

  const isUpgradeIncentiveFlagEnabled = useFeatureFlag(
    Features.COMPETITIVE_INTELLIGENCE_UPGRADE_INCENTIVE
  )

  const setIsBannerDismissed = dismissed => {
    setIsBannerDismissedState(dismissed)

    dispatch({
      type: SET_GLOBAL_DATA,
      payload: { isShowingCIUpgradeBanner: !dismissed }
    })
  }

  const isShowingBanner =
    !isMobile &&
    isUpgradeIncentiveFlagEnabled &&
    isUpgradeIncentiveEligible({
      planId: id,
      billingFrequency: billing_frequency,
      status,
      subscriptionDate: membership_created_at
    }) &&
    !isBannerDismissed

  useEffect(() => {
    dispatch({
      type: SET_GLOBAL_DATA,
      payload: { isShowingCIUpgradeBanner: isShowingBanner }
    })
  }, [dispatch, isShowingBanner])

  if (!isShowingBanner) {
    return null
  }

  const url =
    CI_INCENTIVE_UPGRADE_LINK[group?.toLowerCase()] ||
    CI_INCENTIVE_UPGRADE_LINK.pro

  return (
    <StyledNavbarWrapper id="ci-upgrade-banner">
      <BannerContainer>
        <LeftSection>
          <DealTag>
            <Text variant="headingSm" color="#581CBB">
              {t(
                'competitive_intelligence:upgradeIncentive.limitedTimeOffer',
                'Limited-Time Offer'
              )}
            </Text>
          </DealTag>
          <Text element="p" variant="headingSm" color="white">
            {t(
              'competitive_intelligence:upgradeIncentive.leftSide.header',
              '1 month of Brand Owner + Competitive Intelligence at your current price'
            )}
          </Text>
        </LeftSection>
        <RightSection>
          <LearnMoreButton
            mini
            btnType={ButtonType.SECONDARY_TEXT}
            onClick={() => {
              sendCtaClickEvent({
                text: 'Learn More',
                destination: COMPETITIVE_INTELLIGENCE_URL,
                type: 'button',
                location: 'CI Upgrade Banner'
              })
              history.push(COMPETITIVE_INTELLIGENCE_URL)
            }}>
            {t('generic:learnMore', 'Learn More')}
          </LearnMoreButton>
          <Button
            mini
            btnType={ButtonType.TERTIARY}
            onClick={() => {
              sendCtaClickEvent({
                text: 'Redeem Offer',
                destination: url,
                type: 'button',
                location: 'CI Upgrade Banner'
              })
              window.open(url, '_blank', 'noopener noreferrer')
            }}>
            {t('generic:redeemOffer', 'Redeem Offer')}
          </Button>
          <ClickableIcon
            name="X_CLOSE"
            color="white"
            padding="4px"
            onClick={() => {
              sendCtaClickEvent({
                text: 'Dismiss CI Upgrade Banner',
                type: 'button',
                location: 'CI Upgrade Banner'
              })
              setIsBannerDismissed(true)
              setLocalData(CI_UPGRADE_BANNER_LOCAL_STORAGE_KEY, true)
            }}
          />
        </RightSection>
      </BannerContainer>
    </StyledNavbarWrapper>
  )
}

CIUpgradeBanner.defaultProps = {
  isMobile: false
}

CIUpgradeBanner.propTypes = {
  isMobile: PropTypes.bool
}
