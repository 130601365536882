import React from 'react'
import { useTranslation } from 'react-i18next'

import { DefaultAppOnly } from 'src/js/containers/common/DefaultAppOnly/DefaultAppOnly'
import alertsEmptyStateImg from 'icons/svg/empty-alerted-products.svg'
import { EmptyState, Icon } from '@junglescout/edna'

const AlertsDropdownEmptyState = () => {
  const { t } = useTranslation(['alerts'])

  return (
    <EmptyState
      asCard={false}
      image={alertsEmptyStateImg}
      imageMaxHeight="130px"
      header={t(
        'alerts:AlertsDropdown.emptyState.header',
        'Stay up to date with segment and product changes.'
      )}
      message={
        <>
          {t(
            'alerts:AlertsDropdown.emptyState.subheader',
            'Get daily alerts whenever a segment or product changes. All you have to do is click the Alert icon'
          )}{' '}
          <Icon name="BELL_FILLED" color="grey700" width="14px" height="14px" />{' '}
          .{' '}
          <DefaultAppOnly>
            <a
              href="https://support.junglescout.com/hc/en-us/articles/360037379034?_ga=2.139375091.1136915980.1592263955-1647019793.1570043518"
              target="_blank"
              rel="noopener noreferrer">
              {t('alerts:AlertsDropdown.emptyState.needHelp', 'Need help?')}
            </a>
          </DefaultAppOnly>
        </>
      }
    />
  )
}

export { AlertsDropdownEmptyState }
