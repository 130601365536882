import React from 'react'
import styled from 'styled-components'

import { LoadingIcon } from '@junglescout/edna'

const AlertDiffsLoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 364px;
`

export const AlertsDropdownLoadingState = () => {
  return (
    <AlertDiffsLoadingWrapper>
      <LoadingIcon size="30px" color="primary" />
    </AlertDiffsLoadingWrapper>
  )
}
