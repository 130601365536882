import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { alertDiffMessage } from 'src/js/helpers/alerts'
import { TruncatedText } from 'ui_elements/TruncatedText/TruncatedText'

const AlertDiffRow = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  flex-direction: row;
  padding: 12px 16px;
  height: 84px;
  border-bottom: 1px solid ${props => props.theme.colors.grey100};

  line-height: normal;
  cursor: pointer;
  &:hover {
    background-color: ${props => props.theme.colors.grey050};
  }
`
const AlertDiffDot = styled.div`
  height: 12px;
  width: 12px;
  min-width: 12px;
  border-radius: 50%;
  margin-right: 16px;

  background-color: ${props =>
    props.alertRead ? props.theme.colors.grey600 : props.theme.colors.primary};
`

const StyledTruncatedText = styled(TruncatedText)`
  font-size: 12px !important;
  font-style: normal;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: normal;
  color: #222222;
  font-weight: ${props => (props.alertRead ? 'normal' : 500)};
`

export const AlertsDropdownDiff = ({ diff, handleClick }) => {
  const { t } = useTranslation()
  const { alert } = diff
  const { read_at, product } = alert

  const currency = product ? product.currency : 'USD'
  const alertRead = read_at !== null

  return (
    <AlertDiffRow
      alertRead={alertRead}
      onClick={() => handleClick(alert)}
      role="option">
      <AlertDiffDot alertRead={alertRead} data-testid="alertDiffDot" />
      <StyledTruncatedText numLines={3} alertRead={alertRead}>
        {alertDiffMessage(t, diff, true, currency)}
      </StyledTruncatedText>
    </AlertDiffRow>
  )
}

AlertsDropdownDiff.propTypes = {
  diff: PropTypes.shape({
    key: PropTypes.string,
    old_value: PropTypes.string || PropTypes.number,
    new_value: PropTypes.string || PropTypes.number,
    alert: PropTypes.object
  }).isRequired,
  handleClick: PropTypes.func.isRequired
}
